<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เลือกกลุ่มสินค้า</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <!-- <div class="row d-flex justify-content-center mb-4">
              <label class="col-sm-9 text-start">เลขที่เอกสาร</label>
              <div class="col-sm-8">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div> -->
            <div class="row d-flex justify-content-center text-start mb-4">
              <label for="input1  form-label" class="col-sm-9"
                >ค้นหากลุ่มสินค้า</label
              >
              <div class="col-sm-8">
                <input
                  v-model="searchProduct"
                  type="text"
                  class="form-control"
                  placeholder="ค้นหากลุ่มสินค้า
                      "
                  aria-describedby="basic-addon1"
                  :disabled="isView"
                  list="datalistOptions"
                />
                <datalist
                  id="datalistOptions"
                  v-if="searchListProduct.length >= 1"
                >
                  <option
                    id="datalistOptions11"
                    v-for="(item, index) in searchListProduct"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.name }}
                    <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                  </option>
                </datalist>
              </div>
            </div>

            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-9">Item Group no</label>
              <div class="col-sm-8">
                <input
                  v-model="searchListProduct[0].code"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-9">Item Group Name</label>
              <div class="col-sm-8">
                <input
                  v-model="searchListProduct[0].name"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <!-- // -->
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0] && priority == 4"
            >
              <label class="col-sm-9 required">District</label>
              <div class="col-sm-8">
                <select
                  v-model="searchListProduct[0].districtDiscountId"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="item in districts"
                    :value="item.id"
                    :key="item"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="invalid-feedback text-end">กรุณาเลือก</div>
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0] && priority == 3"
            >
              <label class="col-sm-9 required">Customer</label>
              <div class="col-sm-8">
                <select
                  v-model="searchListProduct[0].customerId"
                  class="form-select"
                  aria-label="Default select example"
                  required
                >
                  <option
                    v-for="item in customers"
                    :value="item.id"
                    :key="item"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="invalid-feedback text-end">กรุณาเลือก</div>
              </div>
            </div>
            <!-- // -->
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-9 required">ประเภทส่วนลด</label>
              <div class="col-sm-8">
                <!-- <div class="col-sm-12"> -->
                <div
                  class="form-check form-check-inline form-check-custom form-check-solid col-sm-12"
                >
                  <input
                    v-model="searchListProduct[0].discountType"
                    required
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="bath"
                    id="flexRadioChecked"
                  />

                  <label
                    class="form-check-label m-4"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >บาท</label
                  >
                  <input
                    v-model="searchListProduct[0].discountType"
                    required
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="percent"
                    id="flexRadioChecked2"
                  />
                  <!-- checked="checked" -->
                  <label
                    class="form-check-label"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >%</label
                  >
                </div>
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-9 required">DsT</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchListProduct[0].discountAmt"
                  required
                />
                <div class="invalid-feedback text-end">กรุณาเลือก</div>
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer>
              <div class="card-toolbar">
                <button
                  @click="submit()"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                >
                  บันทึก
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import whApi from "@/api/warehouse/";
import promotionAPI from "@/api/promotion/";
import customerAPI from "@/api/customer/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  props: {
    dialogAddItem: Boolean,
    warehouseId: String,
    priority: String,
  },
  data: () => ({
    isSubmit: false,
    searchProduct: "",
    searchListProduct: [],
    itemGroup: [],
    stockAmt: "",
    requestStockAmt: "",
    districts: [],
    customers: [],
  }),
  watch: {
    searchProduct(val) {
      console.log("คำค้นหา", val);
      this.addProductItems(val);
    },
    dialogAddItem(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        // console.log("this.priority", typeof this.priority, this.priority);
        if (this.priority == 4 || this.priority == "4") {
          this.getAllDistrict();
        } else {
          this.getAllCustomer();
        }
      }
    },
    searchListProduct(val) {
      if (val.length == 1) {
        console.log("val", val);
        // this.getGroupProductById();
      }
    },
  },
  // productId {{ searchListProduct[0].id }}
  // warehouseId {{ warehouseId }}

  methods: {
    async getAllDistrict() {
      let responseData = [];
      try {
        responseData = await promotionAPI.district.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.districts = responseData.data;
      }
    },
    async getAllCustomer() {
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll(
          localStorage.getItem("branchId"),
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.customers = responseData.data;
      }
    },
    async addProductItems(searchProduct) {
      let responseData = [];
      try {
        responseData = await whApi.itemGroup.search({ search: searchProduct });
        console.log("this.searchListProduct", this.searchListProduct);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.searchListProduct = responseData.data;
      }
    },
    async getGroupProductById() {
      let responseData = [];
      try {
        responseData = await whApi.itemGroup.getOne(
          this.searchListProduct[0].id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.itemGroup = responseData.data[0];
      }
    },
    async submit() {
      let body = {
        itemGroupHeaderId: this.searchListProduct[0].id,
        name: this.searchListProduct[0].name,
        code: this.searchListProduct[0].code,
        ///เดี๋ยวมาต่อ
        districtDiscountId: this.searchListProduct[0].districtDiscountId,
        customerId: this.searchListProduct[0].customerId,
        ///เดี๋ยวมาต่อ
        discountType: this.searchListProduct[0].discountType,
        discountAmt: this.searchListProduct[0].discountAmt,
        priority: this.priority,

        // customerId: 0,
        // discountType
        // discountAmt
      };
      this.$emit("addProduct", body);
      this.$emit("closeDialogAddItem");
    },
    close() {
      this.$emit("closeDialogAddItem");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
